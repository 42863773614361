<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4 grey lighten-4">
      <div class="d-flex align-center justify-space-between mb-4">
        <p class="subtitle-1 ma-0">
          Escolha uma especialidade abaixo para editar ou excluir.
        </p>
        <v-btn v-if="especialidadesCriar" small depressed color="fmq_gray" dark to="/especialidade-criar"
          >Nova Especialidade</v-btn
        >
      </div>
      <EspecialidadesTabelas
        :headers="headers"
        :especialidades="especialidades"
        :loading="loading"
        :total="totalespecialidades"
        :pageText="pageText"
        :pageNumber="page"
        @handleFilter="filter"
        @excluir="excluirModal"
      >
        <template v-slot:nome>
          <v-text-field
            v-model="formData.nome"
            placeholder="Digite o nome"
            solo
            flat
            dense
            :hide-details="true"
            @input="page = 1;getEspecialidades(formData, page)"
            :clearable="true"
            :full-width="true"
          >
          </v-text-field>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-start">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </EspecialidadesTabelas>
    </div>
    <AlertConfirmation
      :dialog="dialog"
      :dialogMessage="dialogMessage"
      @close="dialog = false"
      @accept="excluir"
    />
    <AlertSuccess
      :dialog="success"
      dialogMessage="Especialidade excluida com sucesso"
      @close="
        getEspecialidades(formData, page);
        success = false;
        clear();
      "
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import EspecialidadesTabelas from "@/components/especialidades/EspecialidadesTabelas.vue";
import { buscarEspecialidade, excluirEspecialidade } from "@/services/especialidades";
export default {
  name: "Especialidades",
  components: { EspecialidadesTabelas },
  data: () => ({
    breadcrumbs: [
      {
        text: "Especialidades",
        disabled: true,
        to: "",
      },
    ],
    headers: [
      { text: "Nome da Especialidade Médica", value: "nome", width: "90%", sortable: false  },
      { text: "Ações", value: "id", sortable: false },
    ],
    loading: false,
    especialidades: [],
    totalespecialidades: null,
    pageText: null,
    page: 1,
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: 15,
      nome: null,
    },
    dialog: false,
    dialogMessage: null,
    especialidadeId: null,
    error: false,
    success: false,
    messageError: null,
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    if (this.$store.state.especialidadeSearch.length) {
      this.formData = { ...this.$store.state.especialidadeSearch[0] };
    }
    this.getEspecialidades(this.formData, this.page);
  },
  methods: {
    async getEspecialidades(payload, page) {
      this.produtos = [];
      this.$store.dispatch("setEspecialidadesSearch", payload);
      try {
        this.loading = true;
        const resp = await buscarEspecialidade(payload, page);
        this.especialidades = resp.data.data;
        this.totalespecialidades = resp.data.total;
        this.pageText = `${resp.data.from}-${resp.data.to} de ${resp.data.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.$store.dispatch("setEspecialidadesSearch", {});
      this.formData.nome = null;
      this.page = 1;
      this.getEspecialidades(this.formData, this.page);
    },
    search() {
      this.page = 1;
      this.getEspecialidades(this.formData, this.page);
    },
    filter(event) {
      this.$store.dispatch("setEspecialidadesSearch", this.formData);
      this.formData.orderSorted = event.orderSorted;
      this.formData.perPage = event.perPage;
      this.page = event.page;
      this.getEspecialidades(this.formData, this.page);
    },
    async excluir() {
      this.error = false;
      this.sucess = false;
      try {
        await excluirEspecialidade(this.especialidadeId).then(() => {
          this.dialog = false;
          this.success = true;
        });
      } catch (e) {
        this.dialog = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    excluirModal(item) {
      this.dialogMessage = `Tem certeza que deseja excluir ${item.nome}?`;
      this.dialog = true;
      this.especialidadeId = item.id;
    },
  },
  computed: {
    especialidadesCriar() {
      return this.$store.state.user.data.permissoes.includes(
        "especialidadeMedica.criar"
      );
    },
  },
};
</script>

<style></style>
